/* Reports */
.reports-wrapper {
  height: 50vh;
}

.reports-row {
  width: '100%';
}

/* Terminations Report */
.terminations-table {

}

.terminations-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}